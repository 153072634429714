import { BigNumber } from 'bignumber.js'

export const minusOne = new BigNumber('-1')
export const oneThousandth = new BigNumber('0.0001')
export const zero = new BigNumber('0')
export const one = new BigNumber('1')
export const ten = new BigNumber('10')
export const thousand = new BigNumber('1000')
export const million = new BigNumber('1000000')
export const billion = new BigNumber('1000000000')
