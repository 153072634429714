export const LANDING_PILLS = [
  {
    icon: "ether_circle_color",
    label: "ETH",
    link: "/asset/eth",
  },
  {
    icon: "btc_circle_color",
    label: "BTC",
    link: "/asset/btc",
  },
  {
    icon: "dai_circle_color",
    label: "DAI",
    link: "/asset/dai",
  },
  // {
  //   icon: 'uni_lp_circle_color',
  //   label: 'UNI LP',
  //   link: '/asset/lp-token',
  // },
  // {
  //   icon: 'yfi_circle_color',
  //   label: 'YFI',
  //   link: '/asset/yfi',
  // },
];
